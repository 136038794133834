import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Button, Link } from 'gatsby-theme-material-ui'
import React, { useEffect } from 'react'
import config from '../../config.json'
import Logo from '../assets/svg/logo.svg'
import World from '../assets/svg/world.svg'
import Layout from '../components/layout'
import Product from '../components/product'
import Visual from '../components/visual'
import notFalsyPredicate from '../utils/notFalsyPredicate'

type HomeProps = { data: GatsbyTypes.HomeQuery } & PageProps
const {
  seo: {
    organization: {
      name: organizationName,
      logo: { path: logoPath, width: logoWidth, height: logoHeight },
    },
    website: { name: websiteName, description: websiteDescription },
  },
} = config

const continentData = [
  {
    title: 'Europa',
    top: 13,
    left: 50,
  },
  {
    title: 'Afrika',
    top: 40,
    left: 50,
  },
  {
    title: 'Asien',
    top: 15,
    left: 75,
  },
  {
    title: 'Australien und Ozeanien',
    top: 60,
    left: 75,
  },
  {
    title: 'Karibik',
    top: 38,
    left: 29,
  },
  {
    title: 'Naher Osten',
    top: 25,
    left: 57,
  },
  {
    title: 'Nordamerika',
    top: 23,
    left: 22,
  },
  {
    title: 'Südamerika',
    top: 63,
    left: 30,
  },
] as const

const useStyles = makeStyles((theme) => ({
  visualImage: {
    width: 300,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
  },
  visualBox: {
    backgroundColor: theme.palette.background.paper,
  },
  marker: {
    padding: `${theme.spacing(0.25)}px ${theme.spacing(0.75)}px`,
    color: theme.palette.secondary.main,
    fontSize: '0.875rem',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      border: `1px solid ${theme.palette.secondary.main}`,
      backgroundColor: theme.palette.background.paper,
      borderRadius: 6,
    },
  },
  section: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  centerSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  readMoreButton: {
    marginTop: theme.spacing(2),
  },
  reisefuehrerLink: {
    '& > div > svg': {
      backgroundColor: theme.palette.background.default,
      transition: theme.transitions.create(['background-color']),
    },
    '&:hover': {
      '& > div > svg': {
        backgroundColor: theme.palette.grey[200],
      },
    },
  },
  borderRightGridItem: {
    [theme.breakpoints.up('lg')]: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
}))

const Home = ({
  location,
  data: {
    visualImage,
    historyImage,
    bookTeaserImage,
    smartTeaserImage,
    allMairdumontProduct: { nodes: travelGuides },
  },
}: HomeProps) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.SDG) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.SDG.Publisher.setZone('startseite')
    }
  }, [])
  const classes = useStyles()
  const theme = useTheme()

  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const visual =
    visualImage &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getImage(visualImage)
  const history =
    historyImage &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getImage(historyImage)
  const imageTeaserBook =
    bookTeaserImage &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getImage(bookTeaserImage)
  const imageTeaserSmart =
    smartTeaserImage &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getImage(smartTeaserImage)

  return (
    <Layout
      location={location}
      seoProps={{
        title: websiteName,
        disableTitleSuffix: true,
        description: websiteDescription,
        images: [
          {
            path: logoPath,
            caption: organizationName,
            alt: `${organizationName} Logo`,
            width: logoWidth,
            height: logoHeight,
          },
        ],
      }}
      visual={
        <Visual image={visual}>
          <h1 style={{ margin: 0 }}>
            <Logo
              fill={theme.palette.primary.main}
              className={classes.visualImage}
            />
            <Typography
              component="span"
              variant="h3"
              color="primary"
              display="block"
            >
              Die besten Seiten der Welt
            </Typography>
          </h1>
        </Visual>
      }
    >
      <section className={classes.section}>
        <Typography component="h2" variant="h3" gutterBottom align="center">
          Neuerscheinungen
        </Typography>

        <div style={{ padding: 32 }}>
          <Grid container spacing={8}>
            {travelGuides
              .map(({ id, title, imageUrl, description, productShopLinks }) => (
                <Grid item md={4} key={id}>
                  <Product
                    product={{
                      id,
                      title,
                      imageUrl,
                      description,
                      productShopLinks,
                    }}
                    imageHeight={120}
                  />
                </Grid>
              ))
              .filter(notFalsyPredicate)}
          </Grid>
        </div>
      </section>

      <Divider />

      <section className={classes.centerSection}>
        <Typography component="h2" variant="h3" gutterBottom>
          Reiseführer
        </Typography>

        <Typography gutterBottom>
          Über 180 Titel zu Reisezielen in aller Welt. Als Print-Ausgabe oder
          als E-Book.
        </Typography>

        <Box mt={4} alignSelf="stretch">
          <Link
            to="/reisefuehrer/"
            color="inherit"
            className={classes.reisefuehrerLink}
          >
            <Box style={{ position: 'relative' }}>
              <World fill={theme.palette.primary.main} />

              <Box
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                {continentData.map(({ title, top, left }) => (
                  <Typography
                    key={title}
                    component="span"
                    className={classes.marker}
                    style={{
                      top: `${top}%`,
                      left: `${left}%`,
                    }}
                  >
                    {title}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Link>
        </Box>
      </section>

      <Divider />

      <section className={classes.centerSection}>
        <Typography component="h2" variant="h5" gutterBottom>
          DIE BESTEN SEITEN DER WELT.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Baedeker schlägt die besten Seiten der Welt auf, lädt ein zu wahrem
          Lesevergnügen, eröffnet neue, ungeahnte Zugänge zu seinen Reisezielen
          und bereitet Magische Momente. Informativ und tiefgründig wie je,
          bleibt er doch gelassen. Und das gleich zweifach mit dem Baedeker.Das
          Original und dem Baedeker SMART.
        </Typography>

        <div
          style={{
            marginTop: 16,
            paddingTop: lgUp ? 40 : 20,
            paddingBottom: lgUp ? 40 : 20,
            alignSelf: 'stretch',
          }}
        >
          <Grid container spacing={lgUp ? 10 : 5} justify="center">
            <Grid
              item
              container
              xs={12}
              lg={6}
              className={classes.borderRightGridItem}
              justify="center"
            >
              <Grid item xs={12}>
                <Typography
                  component="h2"
                  variant="h3"
                  gutterBottom
                  align="center"
                >
                  Baedeker.Das Original
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {imageTeaserBook && (
                  <GatsbyImage
                    style={{ marginBottom: theme.spacing(4) }}
                    alt=""
                    image={imageTeaserBook}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="overline">
                  Vorher lesen, wo es nachher am schönsten ist.
                </Typography>
                <Typography>
                  Das Original unter den Reiseführern setzt neue Maßstäbe in
                  Sachen Inhalt, Design und Veredelung. Die bekannten Stärken
                  bleiben: einmaliges Wissen, verlässliche Informationen,
                  gewohnte Übersichtlichkeit
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} lg={6} justify="center">
              <Grid item xs={12}>
                <Typography
                  component="h2"
                  variant="h3"
                  gutterBottom
                  align="center"
                >
                  Baedeker.SMART
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {imageTeaserSmart && (
                  <GatsbyImage
                    style={{ marginBottom: theme.spacing(4) }}
                    alt=""
                    image={imageTeaserSmart}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="overline">
                  Einfach und schnell planen – und ganz besondere Urlaubstage
                  erleben:
                </Typography>
                <Typography>
                  Morgens schnell und unkompliziert den Tag planen – mit dem
                  Baedeker SMART haben Sie den perfekten Reiseführer an Ihrer
                  Seite, auf den Sie sich verlassen können und der die schönste
                  Zeit des Jahres unvergesslich macht
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </section>

      <Divider />

      <section className={classes.section}>
        <div style={{ padding: lgUp ? 40 : 20 }}>
          <Grid container spacing={lgUp ? 10 : 5} justify="center">
            <Grid item xs={8} lg={3} className={classes.gridItem}>
              {history && <GatsbyImage image={history} alt="" />}
            </Grid>

            <Grid item xs={12} lg={7} className={classes.gridItem}>
              <Typography component="h2" variant="h3" gutterBottom>
                Der Verlag
              </Typography>

              <Typography variant="body1" gutterBottom>
                Vor bald 200 Jahren begann in Koblenz eine einmalige
                Erfolgsgeschichte: Karl Baedeker erfand den Reiseführer. Sein
                Name ist seither zum Synonym für Reiseführer geworden. Reisen
                hieß damals Aufbruch in die Fremde, mit vielen Fragen: Wie komme
                ich hin? Wo kann ich schlafen? Was soll ich mir anschauen? Karl
                Baedeker hatte die Antworten: ein &raquo;Handbuch für Reisende,
                die sich leicht und schnell zurechtfinden wollen.&laquo;
              </Typography>

              <Button
                to="/verlag/"
                variant="contained"
                color="secondary"
                className={classes.readMoreButton}
              >
                Mehr erfahren
              </Button>
            </Grid>
          </Grid>
        </div>
      </section>
    </Layout>
  )
}

export default Home
export const homeQuery = graphql`
  query Home {
    visualImage: file(relativePath: { eq: "images/visuals/home.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    historyImage: file(
      relativePath: { eq: "images/content/home/history.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    bookTeaserImage: file(
      relativePath: { eq: "images/content/home/BAE_London_Homepage.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          height: 400
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    smartTeaserImage: file(
      relativePath: { eq: "images/content/home/book2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          height: 400
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    allMairdumontProduct(sort: { fields: releaseDate, order: DESC }, limit: 3) {
      nodes {
        id
        title
        description
        imageUrl
        productShopLinks {
          name
          url
        }
      }
    }
  }
`
