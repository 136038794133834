import { makeStyles } from '@material-ui/core'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser'
import {
  FileNode,
  IGatsbyImageDataParent,
} from 'gatsby-plugin-image/dist/src/components/hooks'
import React, { forwardRef, PropsWithChildren } from 'react'

export type VisualProps = {
  image: FileNode | IGatsbyImageDataParent | IGatsbyImageData | undefined
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  visual: {
    position: 'relative',
  },
  visualImage: {
    minHeight: 350,
    [theme.breakpoints.up('lg')]: {
      minHeight: 500,
    },
  },
  children: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(4),
    backgroundColor: 'rgba(255,255,255,0.6)',
  },
}))

const Visual = forwardRef<HTMLDivElement, PropsWithChildren<VisualProps>>(
  ({ image, children }, ref) => {
    const classes = useStyles()

    const gatsbyImageData =
      image &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getImage(image)
    return (
      <div className={classes.visual} ref={ref}>
        {gatsbyImageData && (
          <GatsbyImage
            image={gatsbyImageData}
            alt=""
            className={classes.visualImage}
          />
        )}
        <div className={classes.children}>{children}</div>
      </div>
    )
  },
)

Visual.displayName = 'Visual'

export default Visual
